<template>
  <div>
    <div style="text-align:right; border-bottom: 1px solid #EBEEF5;">
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="bgFFF">
      <el-form label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="广告ID:">
              {{ advert.advertId }}
            </el-form-item>
            <el-form-item label="状态:">
              {{ advert.putStatus | statusFilter }}
            </el-form-item>
            <el-form-item label="广告图片:">
              <img :src="advert.imgUrl" style="width: 200px">
            </el-form-item>
            <el-form-item label="标题:">
              {{ advert.title }}
            </el-form-item>
            <el-form-item label="分类:">
              {{ categoryName }}
            </el-form-item>
            <el-form-item label="区域:">
              {{ areaList | cityFilter }}
            </el-form-item>
            <el-form-item label="展示模块:">
              {{ moduleName }}
            </el-form-item>
            <el-form-item label="展示位:">
              {{ moduleLocName }}
            </el-form-item>
            <el-form-item label="展示时间:">
              <div style="width: 290px">{{ publishList | publishListFilter }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跳转类型:">
              {{ pageName ? pageName : advert.jumpUrl }}
            </el-form-item>
            <el-form-item label="创建时间:">
              {{ advert.createTime }}
            </el-form-item>
            <el-form-item label="最后操作人:">
              {{ updateUserName }}
            </el-form-item>
            <el-form-item label="最后修改时间:">
              {{ advert.updateTime }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    statusFilter (val) {
      const arr = ['已保存', '待上架', '已上架', '已下架']
      return arr[val]
    },
    cityFilter (val) {
      if (!val || val.length === 0) {
        return
      }
      return val.join('、')
    },
    publishListFilter (val) {
      if (!val || val.length === 0) {
        return
      }
      let str = ''
      val.forEach(item => {
        str += `${item.startDate} ～ ${item.endDate} ${item.lowerTime} ～ ${item.upperTime} `
      })
      return str
    }
  },
  data () {
    return {
      id: this.$route.query.advertId,
      advert: {},
      areaList: [],
      publishList: [],
      categoryName: '',
      updateUserName: '',
      moduleName: '',
      moduleLocName: '',
      pageName: ''
    }
  },
  mounted() {
    this.getDetailInfo()
  },
  methods: {
    getDetailInfo () {
      const opt = {
        url: '/acb/2.0/advert/detail/' + this.id,
        method: 'get',
        data: {},
        success: res => {
          this.advert = res.value.advert
          this.areaList = res.value.areaList
          this.publishList = res.value.publishList
          this.categoryName = res.value.categoryName
          this.updateUserName = res.value.updateUserName
          this.moduleName = res.value.moduleName
          this.moduleLocName = res.value.moduleLocName
          this.pageName = res.value.pageName
        }
      }
      this.$request(opt)
    }
  }
}
</script>
