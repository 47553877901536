var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          "text-align": "right",
          "border-bottom": "1px solid #EBEEF5",
        },
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bgFFF" },
      [
        _c(
          "el-form",
          { attrs: { "label-width": "120px" } },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "广告ID:" } }, [
                      _vm._v(" " + _vm._s(_vm.advert.advertId) + " "),
                    ]),
                    _c("el-form-item", { attrs: { label: "状态:" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("statusFilter")(_vm.advert.putStatus)) +
                          " "
                      ),
                    ]),
                    _c("el-form-item", { attrs: { label: "广告图片:" } }, [
                      _c("img", {
                        staticStyle: { width: "200px" },
                        attrs: { src: _vm.advert.imgUrl },
                      }),
                    ]),
                    _c("el-form-item", { attrs: { label: "标题:" } }, [
                      _vm._v(" " + _vm._s(_vm.advert.title) + " "),
                    ]),
                    _c("el-form-item", { attrs: { label: "分类:" } }, [
                      _vm._v(" " + _vm._s(_vm.categoryName) + " "),
                    ]),
                    _c("el-form-item", { attrs: { label: "区域:" } }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("cityFilter")(_vm.areaList)) + " "
                      ),
                    ]),
                    _c("el-form-item", { attrs: { label: "展示模块:" } }, [
                      _vm._v(" " + _vm._s(_vm.moduleName) + " "),
                    ]),
                    _c("el-form-item", { attrs: { label: "展示位:" } }, [
                      _vm._v(" " + _vm._s(_vm.moduleLocName) + " "),
                    ]),
                    _c("el-form-item", { attrs: { label: "展示时间:" } }, [
                      _c("div", { staticStyle: { width: "290px" } }, [
                        _vm._v(
                          _vm._s(_vm._f("publishListFilter")(_vm.publishList))
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "跳转类型:" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.pageName ? _vm.pageName : _vm.advert.jumpUrl
                          ) +
                          " "
                      ),
                    ]),
                    _c("el-form-item", { attrs: { label: "创建时间:" } }, [
                      _vm._v(" " + _vm._s(_vm.advert.createTime) + " "),
                    ]),
                    _c("el-form-item", { attrs: { label: "最后操作人:" } }, [
                      _vm._v(" " + _vm._s(_vm.updateUserName) + " "),
                    ]),
                    _c("el-form-item", { attrs: { label: "最后修改时间:" } }, [
                      _vm._v(" " + _vm._s(_vm.advert.updateTime) + " "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }